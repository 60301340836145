import React from 'react';

import Logo from '../../assets/img/logo.png';
import Contact from '../../assets/img/contact.svg';

import './style.css';
import { useEffect } from 'react';

const Header: React.FC = () => {
  useEffect(() => {
    const button = document.querySelector('#header .menu-burg');

    button?.addEventListener('click', () => {
      button.classList.toggle('active');
    });
  }, []);

  return (
    <div id="header">
      <div className="container">
        <header>
          <nav className="navbar">
            <a href="/" className="nav-brand">
              <img src={Logo} alt="Back Indústria" width="100%" />
            </a>

            <div className="menu-burg">
              <div></div>
              <div></div>
              <div></div>
            </div>

            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="#about">Sobre a back</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#seller">Lojistas</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#store">Produtos</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">Contato</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="tel:+554431421008">
                  (44) 3142-1008
                  <img src={Contact} alt="Contato" />
                </a>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    </div>
  )
}

export default Header;