import React from 'react';
import './App.css';

import Header from './components/header';
import Slides from './components/slides';
import About from './components/about';
import Seller from './components/seller';
import Store from './components/store';
import Contact from './components/contact';
import Footer from './components/footer';

const App: React.FC = () => {
  return (
    <>
    <Header />
    <Slides />
    <About />
    <Seller />
    <Store />
    <Contact />
    <Footer />
    </>
  );
}

export default App;
