import React from 'react';

import './style.css';

const About: React.FC = () => {
  return (
    <div id="about">
      <div>
        <h1>
          <span>Sobre a</span>
          back indústria
        </h1>

        <p>A BACK Indústria foi fundada em 2020, por meio da aquisição de uma renomada empresa do segmento de esquadrias metálicas com mais de 40 anos de experiência.</p>
        <p>Voltada para o ramo da construção civil, a BACK possui equipamentos e estrutura modernas, visando a produção de peças de alta qualidade, priorizando sempre a satisfação dos nossos clientes. </p>
        <p>Confira nosso <a href="https://drive.google.com/file/d/1UTa8kVC_WZUsZcuJeRIt4eximInftLpc/view" target="_blank">catálogo digital de produtos</a>.</p>
      </div>
      <ul>
        <li>
          METAL BACK
        </li>
        <li>
          ALUMIBACK
        </li>
        <li>
          TEMPERBACK
        </li>
      </ul>
    </div>
  )
}

export default About;