import React, { useEffect } from 'react';

import Capa from '../../assets/img/capa.png';
import CapaMobile from '../../assets/img/capa-mobile.png';

import CapaMetal from '../../assets/img/metalback.png';
import CapaMetalMobile from '../../assets/img/metalback-mobile.png';

import './style.css';

const Slides: React.FC = () => {
  useEffect(() => {
    const slides = document.querySelectorAll('#slides picture');
    const navogators = document.querySelectorAll('#slides .target');

    const removeActiveNavgator = () => {
      document.querySelector('#slides .navigation .active')?.classList.remove('active');
    }

    const removeActiveSlide = () => {
      document.querySelector('#slides picture.active')?.classList.remove('active');
    }

    const handleActived = (index: number) => {
      slides[index].classList.add('active');
      navogators[index].classList.add('active');
    }

    let count = 0;
    const startTimeOut = () => {
      return setInterval(() => {
        count++;

        if (count === slides.length) {
          count = 0;
        }

        removeActiveSlide();
        removeActiveNavgator();
        handleActived(count);
      }, 5000);
    }

    let timeout = startTimeOut();

    navogators.forEach((element, index) => {
      element.addEventListener('click', () => {
        removeActiveSlide();
        removeActiveNavgator();

        handleActived(index);

        count = index;
        clearInterval(timeout);
        timeout = startTimeOut();
      })
    });

    return () => {
      clearInterval(timeout);
    }
  }, []);

  return (
    <div id="slides">
      <picture className="active">
        <source srcSet={Capa} />
        <source srcSet={CapaMobile} media="(max-width: 768px)" />
        <img src={Capa} alt="Back Indústria" width="100%" />
      </picture>
      <picture>
        <source srcSet={CapaMetal} />
        <source srcSet={CapaMetalMobile} media="(max-width: 768px)" />
        <img src={CapaMetal} alt="Back Indústria" width="100%" />
      </picture>
      <div className="navigation">
        <div className="target active"></div>
        <div className="target"></div>
      </div>
    </div>
  )
}

export default Slides;