import React from 'react';

import './style.css';

const Store: React.FC = () => {
  return (
    <div id="store">
      <img src="https://i.ibb.co/qDqSNkr/foto-janela-1.jpg" alt="MetalBack" />
      <div className="call-store">
        <div className="bg-circle"></div>
        <h4>Uma Linha Completa feita para você.</h4>
        <p>A Back Indústria oferece uma ampla gama de produtos – porta, janelas, alçapões, com a garantia de alto nível de qualidade.</p>
        <a href="https://drive.google.com/file/d/1UTa8kVC_WZUsZcuJeRIt4eximInftLpc/view" target="_blank">Quero conhecer</a>
      </div>
    </div>
  )
};

export default Store;