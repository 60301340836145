import React from 'react';

import './style.css';

const Seller: React.FC = () => {
  return (
    <div id="seller">
      <div className="title">
        <h2>
          <span>Eu sou</span>
          lojista
        </h2>
      </div>
      <div className="content">
        <p>Você é lojista, possui CNPJ para revenda, gostaria de conhecer e trabalhar com os nossos produtos? <a href="#contact">Clique aqui</a> e preencha o formulário que em breve entraremos em contato.</p>
        <p>A Back Industria é uma empresa especializada em esquadrias e beneficiamento de vidros. Conta com uma equipe preparada e antenada a todas as evoluções da construção civil.</p>
      </div>
    </div>
  )
}

export default Seller;