import React, { useEffect } from 'react';

import TerrMap from '../GoogleMap';

import './style.css';

const Contact: React.FC = () => {
  useEffect(() => {
    const form = document.getElementById("contactForm");
    const button = document.querySelector("#contactForm button");

    button?.addEventListener('click', async (event) => {
      event.preventDefault();

      button.innerHTML = 'Aguarde ...';
      button.setAttribute('disabled', '');

      // const formData: any = { };
      // const fields = document.querySelectorAll('input, textarea');

      // fields.forEach((element) => {
      //   const item = element as HTMLInputElement | HTMLTextAreaElement;

      //   formData[String(item.getAttribute('name'))] = item.value;
      // });

      // const request = {
      //   method: 'POST',
      //   body: JSON.stringify(formData),
      //   headers: {
      //     // 'Accept': 'application/json',
      //     'Content-Type': 'application/json; charset=UTF-8'
      //   }
      // }

      // const {status, message} = await fetch('/api/contact/', request).then(response => response.json());

      const divMessage = document.createElement('div');
      divMessage.classList.add('message');
      // divMessage.innerHTML = message;
      divMessage.innerHTML = 'Falha ao enviar sua mensagem, tente novamente mais tarde.';
      divMessage.setAttribute('style', 'color: #e74c3c');

      // if (status === "success") {
      //   divMessage.setAttribute('style', 'color: #27ae60');
      // }

      // if (status === "error") {
      //   divMessage.setAttribute('style', 'color: #e74c3c');
      // }

      form?.insertBefore(divMessage, form.firstChild);

      button.innerHTML = 'Enviar';
      button.removeAttribute('disabled');
    })
  }, []);

  return (
    <div id="contact">
      <div className="number">
        R: Carlos Fuchs, 120, Nova Esperança - PR - (44) 3142-1008
      </div>
      <div id="googleMaps">
        <TerrMap />
      </div>
      <form id="contactForm">
        <h2>
          <span>Entre em contato</span>
          CONOSCO
        </h2>
        <div className="field">
          <input name="name" placeholder=" " />
          <span>Nome completo</span>
        </div>
        <div className="field">
          <input type="email" name="email" placeholder=" " />
          <span>Email</span>
        </div>
        <div className="field">
          <input type="tel" name="number" placeholder=" " />
          <span>Contato</span>
        </div>
        <div className="field">
          <textarea name="message" placeholder=" "></textarea>
          <span>Mensagem</span>
        </div>
        <button>Enviar</button>
      </form>
    </div>
  )
}

export default Contact;