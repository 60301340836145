import React from 'react';

import Logo from '../../assets/img/logo.png';
import Facebook from '../../assets/img/facebook.svg';
import Instagram from '../../assets/img/instagram.svg';

import './style.css';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-logo">
          <img src={Logo} alt="Back Indústria" />
        </div>
        <div className="footer-text">
          Back Indústria - CNPJ: 36.738.592/0001-90 © Todos os direitos reservados. 2020
        </div>
        <div className="footer-items">
          <ul>
            <li>
              <a href="linkaqui">
                <img src={Facebook} alt="Facebook" />
              </a>
            </li>
            <li>
              <a href="linkaqui">
                <img src={Instagram} alt="Instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer;