import * as React from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { compose, withProps } from 'recompose';


const mapProps = {
    center: {lat: -23.196650, lng: -52.200080},
    position: {lat: -23.197270, lng: -52.200087},
}

const TerrMap: React.FC = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC0swLUb-dVREaUyJoz2MDiQMLDkfqUANA&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: "100%" }} />,
        containerElement: <div style={{ height: "100%" }} />,
        mapElement: <div style={{ height: "100%" }} />,
      }),
      withScriptjs,
      withGoogleMap
)( (props: any) => {

    return (
        <GoogleMap defaultZoom={18} defaultCenter={mapProps.center}>
            <Marker position={mapProps.position} />
        </GoogleMap>
    );
} ) as any;

export default TerrMap;